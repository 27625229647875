.filtre {
  background: $color-secondary;
  padding: 30px 0;
  .container {
    @extend .flex;
    @extend .items-center;
    @extend .justify-center;
    select {
      background: $color-secondary;
      border: 2px solid $color-white;
      height: 40px;
      border-radius: 0;
      font-size: 20px;
      color: $color-white;
      padding: 0 20px;
    }
  }
}
