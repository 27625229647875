.content-link {
  width: 318px;
  height: 75px;
  top: 25px;
  position: relative;
  background: #00355f;
  @media screen and (max-width: 480px) {
    width: 290px;
    transition: .5s;
  }
  a.btn-mdw {
    color: $color-white;
    text-align: center;
    margin: 0 auto;
    display: table;
    border: 1px solid $color-white;
    position: relative;
    top: 4px;
    height: 88%;
    width: 97%;
    font-size: 20px;
    margin: 5px;
    padding: 7px 16%;
    line-height: 25px;
    @media screen and (max-width: 480px) {
      font-size: 18px;
      transition: .5s;
    }
  }
  a.btn-mdw:hover {
    color: $color-white;
    background: #095797;
  }
}

.bursts {
  .container-fluid {padding: 0;}
    .bursts-container {
        @extend .flex;
        @extend .flex-wrap;
        .burst {
            background-position: center;
            background-size: 100% 100%;
            background-repeat: no-repeat;
            width: 50%;
            position: relative;
            height: calc(100vw * 450 / 1920);
            &.burst-small {
              width: 33.33%;
            }
            .border {
              position: absolute;
              top: 15px;
              left: 15px;
              right: 15px;
              bottom: 15px;
              border: 2px solid #aaa;
              @extend .flex;
              @extend .justify-center;
              @extend .items-center;

              .icon {
                width: 35%;
                .overlay {
                  width: 80%;
                  margin: auto;
                  background-position: center;
                  background-size: 100% 100%;
                  background-repeat: no-repeat;
                  img {
                    opacity: 0;
                    width: 100%;
                    transition: all 0.3s ease;
                  }
                }
              }
              .text-box {width: 60%;}
              h3 {
                  @extend .font-1;
                  font-size: 3vw;
                  color: #aaaaaa;
                  font-weight: 300;
                  transition: all 0.3s ease;
                  span {
                    font-weight: 500;
                    text-transform: uppercase;
                  }

              }
            }



            &:hover {
              .border {
                .icon {
                  .overlay {
                    img {
                      opacity: 1;
                    }
                  }
                }
                h3 {color: $color-white;}
              }
            }

            @media screen and (max-width: 767px){
              &, &.burst-small {
                width: 100%;
                height: 250px;
                .border {
                  h3 {font-size: 18px;}
                }
              }
            }
        }
    }
}
