.equipe {
  .item {
    margin: 50px auto;
    @extend .flex;
    align-items: flex-start!important;
    .portrait {
        width: 25%;
        //border: 1px solid $color-black;
        img {min-width: 100%;}
      }
    .text-side {
      width: 75%;
      .nom {
        background-image: url('../images/notre_equipe_texture_strip.jpg');
        background-size: cover;
        h3 {
            @include text-style(35px, $color-grey-text,$font-family-2,500);
            margin: 0;
            padding: 15px;
        }
      }
      .desc {
        background: $color-grey-light;
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: 40px;
        padding: 15px;
        h4 {
          @include text-style(22px, #fff,$font-family-2,400);
          margin: 0;
          text-transform: uppercase;
        }
      }
      .text-box {
        padding: 15px;
        p {
          @include text-style(18px, $color-grey-text,$font-family-1,400);
          margin: 0;
          padding: 0;
          line-height: 1.3;
        }
        h4 {
          @include text-style(22px, #000,$font-family-2,400);
          padding: 15px 0;
        }
        li {color: $color-black;}

      }
    }
    @media screen and (max-width: 1200px) {
      .text-side {
        .nom {
          h3{font-size: 30px;}
        }
      }
    }
    @media screen and (max-width: $size-xs-max) {
      flex-direction: column;
      margin: 30px auto;
      .portrait {
        width: inherit;
        max-width: 200px;
      }
      .text-side {
        width: 100%;
        .nom {
          h3{
            text-align: center;
            font-size: 22px;
          }
        }
        .desc {
          padding: 10px;
          text-align: center;
          flex-direction: column;
        }
        .text-box {
          text-align: center;
          p {
            font-size: 16px;
          }
        }
      }
    }
  }
}
