.section-text-title {
  background-image: url('../images/residentiel_auvent_section_01-bg.jpg');
  background-position: top left;
  background-size: cover;
  background-repeat: no-repeat;
  .container-fluid {padding: 0;}
  padding: 7vh 0;
  .row {
    @extend .flex;
    @extend .flex-wrap;
    @extend .items-center;
    @extend .justify-start;
    .left {
      & > img {margin: 0 auto 30px auto;}
      .title-box {
        background: $color-secondary;
        margin-right: 10%;
        p {
          text-align:right;
          color:$color-white;
          padding: 0 10% 20px 0;
        }
        h3 {
          font-size: 4vw;
          color: $color-white;
          font-weight: 300;
          padding: 20px 10% 20px 0;
          text-align: right;
          @media screen and (min-width: 1920px) {
            font-size: 90px;
          }
        }
      }
    }
   .right {
     padding: 20px 30px;
     p {
       padding: 20px 0;
       text-align: justify;
     }
     h3 {
       color: $color-secondary;
       padding: 20px 0;
       text-align: justify;
     }
     ul {
       padding-left: 25px;
       li {
         line-height: 1.5;
         text-align: justify;
       }
     }
   }
  }
  @media screen and (max-width: $size-xs-max) {
    .row {
      .left {
        .title-box {
          margin-right: 0;
          h3 {
            padding: 20px 10px;
            text-align: center;
            font-size: 30px;
          }
          p {
            text-align: center;
            padding: 0 0 20px 0;
          }
        }
      }
    }
  }

}
