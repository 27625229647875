.brochures {
  .item {
    margin: 50px auto;
    .text-side {
      .nom {
        background: $color-secondary;
        display: flex;
        flex-wrap: wrap;
        .icon {
          margin: 15px;
          max-height: 50px;
          max-width: 50px;
          &:hover {opacity: 0.6;}
        }
        h3 {
            @include text-style(30px, #fff,$font-family-2,500);
            margin: 0;
            margin-right: auto;
            padding: 15px;
        }
      }

      .text-box {
        padding: 15px;
        p {
          @include text-style(18px, #000,$font-family-1,400);
          margin: 0;
          line-height: 1.3;
        }
        h4 {
          @include text-style(22px, #000,$font-family-2,400);
          padding: 15px 0;
        }
        li {color: $color-black;}
      }
    }
    @media screen and (max-width: 1200px) {
      .text-side {
        .nom {
          h3{font-size: 30px;}
        }
      }
    }
    @media screen and (max-width: $size-xs-max) {
      flex-direction: column;
      margin: 30px auto;
      .text-side {
        .nom {
          h3 {
            font-size: 24px;
            text-align: center;
          }
        }
        .text-box {
          text-align: center;
          p {font-size: 16px;}
        }
      }
    }
  }
}
