.module-header-pages {
  position: relative;
  .caption {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    text-align: center;
    @extend .flex;
    @extend .items-center;
    @extend .justify-center;
    @extend .flex-column;
    h3 {
      color: #fff;
      font-size: 4vw;
      font-weight: 300;
      text-align: center;
      padding: 15px 50px;
      text-transform: uppercase;
      border: 1px solid $color-white;
      text-shadow: 0 0 10px #000;
      font-family: $font-family-2;
      @include transition(opacity .75s ease-in);
    }
    h4 {
      margin-top: 20px;
      font-size: 1.5vw;
      color: $color-white;
      text-transform: uppercase;
      text-shadow: 0 0 10px #000;
      font-weight: 400;
      span {
        text-transform: none;
      }
    }
    @media screen and (max-width: 768px) {
      h3 {font-size: 26px;}
      h4 {font-size: 20px}
    }
  }
}
