// IMPORT ADDONS
@import 'addons/bootstrap/bootstrap';

/* purgecss start ignore */

 @import 'addons/font-awesome/font-awesome';
// @import 'addons/owl-carousel2/owl.carousel';
@import 'addons/layerslider/layerslider';
@import 'addons/magnific-popup/magnific-popup';
// @import 'addons/cube-portfolio/cubeportfolio';
// @import 'addons/parallax-scroll/normalize';
// @import 'addons/parallax-scroll/main';

/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'reset';
@import 'variables';
@import 'functions';

/* GENERAL TEMPLATE */

body {
    background-color: $bg-color-body;

    header {
      position: absolute;
    }

    h1.referencement {
        display: none !important;
    }
    .appear {
        opacity: 0;
        @include transition(opacity 0.75s ease-in);
    }
    .parallax, .parallax-slow, .parallax-fast {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }

    .uppercase {
      text-transform: uppercase;
    }
}

div.content-btn-border {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 45px;
  padding: 45px 0;
  transition: .5s;

  @media screen and (max-width: 480px) {
    justify-content: center;
    transition: .5s;
  }

  a.btn-border {
    width: 300px;
    min-width: 300px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: $color-2;
    padding: 5px;
    &:hover {
      background: $color-2;
      transition: .7s;
      span  {
        background: $color-blue-light;
        transition: .5s;
      }
    }
    span {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      text-align: center;
      color: $color-white;
      transition: .5s;
      border: 1px solid $color-white;
      margin-right: 10px;
      font-weight: 400;
      line-height: 25px;
      transition: .5s;
      padding: 5px;
      @media screen and (max-width: 991px) {
        font-weight: 300;
        font-size: 18px;
        transition: .5s;
      }
    }
  }
}

/** Add this class to any anchor tag so it clear the fixed menu (used in forms) */
.jumptarget::before {
  content:"";
  display:block;
  height:$size-nav-height; /* fixed header height*/
  margin:-$size-nav-height 0 0; /* negative fixed header height */
}
.gras {font-weight: 700;}

.btn {
  padding: 15px 50px;
  color: $color-white;
  display: inline-block;
  width: auto;
}
/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or module-menu-centre.php
/*****************************************************/
@import 'menu';
// @import 'menu-centre';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/


@import 'sections/section-text-title';

@import 'sections/section-filtre';


/*****************************************************/
/* Sections (to use with /modules/module-*-*.php)
/*****************************************************/
@import 'modules/module-header-page';

@import 'modules/module-burst';

@import 'modules/soumission';

@import 'modules/module-brochures';

@import 'modules/module-equipe';
/*******************************************************************************
* COMMON TO ALL PAGES
*/


/*****************************************************/
/* INDEX.PHP
/*****************************************************/


/*******************************************************************************
* X PAGE
*/

.index {
    section.section01 {
          background-image: url('../images/section_01_bg.jpg');
          background-position: center;
          background-repeat: no-repeat;
          background-attachment: fixed;
          background-size: cover;
          padding: 100px 0;
          .container-fluid {padding: 0;}
          .left {
            text-align: right;
            h3 {
              color: rgb(0, 53, 95);
              font-size: 4vw;
              font-weight: 300;
              padding-top: 20px;
              padding-bottom: 5vw;
            }
            img {
              margin: auto;
              margin-right: 0;
            }
          }
          .right {
            p {
              padding: 25px 10px;
              border-bottom: 2px solid #AAAAAA;
              padding-right: 15%;
              width: 100%;
              float: left;
              text-align: justify;
            }
            p.pline {
              padding: 0;
              border-bottom: 2px solid #AAAAAA;
            }
            ul {
              padding-left: 20px;
              li {
                text-transform: uppercase;
                line-height: 1;
                padding: 15px 0;
                a {
                  font-weight: 700;
                  &:hover{color: $color-blue-light;}
                }
              }
            }
          }
    }
}

//PAGE FAQ       ///////////////////////////////////////////

.faq {
  & > .section-text-title {
    .right {
      p, h3 {
        padding-left: 5%;
        padding-right: 5%;
      }
      p {border-bottom: 2px solid $color-secondary;}
    }
  }
}

//PAGE CARRIÈRES  ///////////////////////////////////


.carrieres {
  & .section-text-title .row {
    display: block;
  }
  h3 {color: $color-secondary;}
  p {
    padding: 15px 0 0 0;
    &.date {font-weight: 700;}
  }
  & .item {
    border-top: 1px solid $color-secondary;
    padding-top: 25px;
    margin-bottom: 50px;
  }
}

//PAGE REALISATIONS  ///////////////////////////////////
.link-goback {
  h3 {
    color: $color-blue!important;
    padding: 0!important;
  }
  a:hover {
    h3 {color: $color-blue-light!important;}
  }
}
.realisations {
  @extend .flex;
  @extend .flex-wrap;
  @extend .items-start;
  @extend .justify-around;
  .content_wrap {
    width: 18%;
    margin: 30px 0;
    img {
      border: 2px solid $color-white;
      box-shadow: 0 0 20px $color-black;
    }
    p {
      color: $color-black;
      padding: 15px 0 0 0;
      margin: 0;
      text-align: center;
      text-transform: uppercase;
    }
  }


  //Magnific popup galerie style
  .mfp-with-zoom .mfp-container,
  .mfp-with-zoom.mfp-bg {
    opacity: 0;
    visibility: hidden;
    /* ideally, transition speed should match zoom duration */
    @include transition(all 0.3s ease-out);
  }

  .mfp-with-zoom.mfp-ready .mfp-container {
      opacity: 1;
  }
  .mfp-with-zoom.mfp-ready.mfp-bg {
      opacity: 0.8;
  }

  .mfp-with-zoom.mfp-removing .mfp-container,
  .mfp-with-zoom.mfp-removing.mfp-bg {
    opacity: 0;
  }
}




//PAGE COORDONNEES   ///////////////////////////////

.coordonnees {
  .section-02 {margin-top: 50px;}
  background: $color-grey-bg;
  .container-fluid {padding: 0;}
  .text-box {
    width: 80%;
    text-align: right;
    margin: auto;
    padding-top: 20px;
    border-bottom: 1px solid $color-secondary;
    & > * {padding: 5px 0;}
    .title {
      font-size: 40px;
      text-transform: uppercase;
      color: $color-secondary;
      font-weight: 500;
      margin: 20px 0;
    }
    h4, span, a {
      font-size: 26px;
      font-weight: 700;
    }
    p {
      font-size: 24px;
      text-align: right;
    }

    a {
      &.phone {
        font-size: 30px;
        font-weight: 400;
      }
      &:hover {
        color: $color-secondary;
      }
    }
      @media screen and (max-width: 768px) {
          text-align: center;
          p {
              text-align: center;
          }
      }

  }

}
/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/
@media screen and (max-width: $size-md-max){
    /*******************************************************************************
    * COMMON TO ALL PAGES
    */

    /*******************************************************************************
    * X PAGE OVERRIDES
    */

}

@media screen and (max-width: $size-sm-max){
    /*******************************************************************************
    * COMMON TO ALL PAGES
    */

    /*******************************************************************************
    * X PAGE OVERRIDES
    */

}

@media screen and (max-width: $size-xs-max){
    /*******************************************************************************
    * COMMON TO ALL PAGES
    */
    section.header {
        h2 {
            font-size: 3em;
        }
    }
    /*******************************************************************************
    * ACCUEIL PAGE OVERRIDES
    */
    .index {
      section.section01 {
        .left {
          h3 {
            font-size: 30px;
            text-align: center;
            padding-bottom: 50px;
          }
          img {margin-right: auto;;}
        }
        .right {
          p {
            padding-right: inherit;
            text-align: center;
          }
        }
      }
    }


    /***************************************************************************/
    /* CARRIÈRES PAGE OVERRIDES
    */


    /***************************************************************************/
    /* REALISATIONS PAGE OVERRIDES
    */
    .realisations {
      .content_wrap {
        width: 100%;
        max-width: 300px;
      }
    }
    /*******************************************************************************
    * COORDONNEES PAGE OVERRIDES
    */
    .coordonnees {
      .text-box {
        .title {font-size: 26px}
        h4, span, a {font-size: 22;}
        p {font-size: 18px;}
        a {
          &.phone {
            font-size: 24px;
          }
        }
      }
    }
}
