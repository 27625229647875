.slideshow-wrap {
    position: relative;
    #layerslider { display: block; }
    #layerslider-mobile { display: none; }
    @media screen and (max-width: $size-slider-breakpoint) {
        #layerslider { display: none; }
        #layerslider-mobile { display: block; }
    }

    .slideshow-next {
        right: 50px;
    }
    .slideshow-prev {
        left: 50px;
    }
    .subtitle {
        color: $color-white;
        font-size: 5vw;
        font-weight: 500;
        letter-spacing: -4px;
        text-shadow: 2px 2px 20px $color-black,-2px -2px 20px $color-black;
        //@extend .font-1;
        @media screen and (max-width: 991px) {
            letter-spacing: 2px;
            margin-top: 45px;
            line-height: 5px;
        }
          
        @media screen and (max-width: 480px) {
            margin-top: 5px;
            letter-spacing: 2px;
            line-height: 5px;
            padding: 15px;
        }
    }
    .title {
        text-transform: uppercase;
        color: $color-white;
        font-size: 5vw;
        font-weight: 300;
        top: 14px;
        position: relative;
        text-shadow: 2px 2px 20px $color-black,-2px -2px 20px $color-black;
        @media screen and (max-width: 991px) {
            padding: 15px;
            line-height: 35px;
            //padding-bottom: 45px;
        }
    }
    .ls-l {
      left: 10%!important;
      top: 26%!important;
      line-height: 90px !important;
    }
    .ls-l a {
        width: auto;
    }
    @media screen and (max-width: $size-xs-max) {
        .ls-l a {
            font-size: 11px;
            border-width: 1px;
            padding: 5px;
        }
    }
    @media screen and (max-width: $size-slider-breakpoint) {
      .ls-l {
        left: 0!important;
        top: auto!important;
        bottom: 0!important;
      }
        .title {
            font-size: 26px;
        }
        .subtitle {
            font-size: 16px;
        }

    }
}
