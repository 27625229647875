// CSS Variables for the project.

// Mettre les différentes variables tirées du fichier psd du designer

//********************
// FONTS
// >> Allez configurer les fonts dans _fonts.scss

// font size dans le document PSD:
$font-size-base-lg: 18px;
$font-size-base-md: 16px;
$font-size-base-sm: 14px;
$font-size-base-xs: 12px;
@import 'font-sizes'; // variables for font-sizes using rem units

/********************/
// COLORS
//
$color-black: #000;
$color-white: #fff; // needed
$color-grey-bg: #f0f0f0;
$color-grey: #58585a;  // needed
$color-grey-dark: #353535; // needed body
$color-grey-darker: #262523; // footer
$color-grey-darker-trans8: rgba(38,37,35,0.85);
$color-grey-light: #b5b5b5;
$color-grey-text: #515115;
$color-1: #b3a016; // needed (red)
$color-primary: $color-1;
$color-2: #00355f; // needed (orange)
$color-secondary: $color-2;
$color-blue: #006dc7;
$color-blue-light: #095797;

// TEXT COLORS

$color-text-base: $color-2; // Mettre la couleur
$color-text-punch: $color-grey-darker; // Mettre la couleur
$color-text-contrast: $color-white; // Mettre la couleur

// $color-text-base-light: lighten($color-text-base, 20%);
// $color-text-punch-light: lighten($color-text-punch, 20%);
// $color-text-contrast-light: lighten($color-text-contrast, 20%);
// $color-text-base-dark: darken($color-text-base, 20%);
// $color-text-punch-dark: darken($color-text-punch, 20%);
// $color-text-contrast-dark: darken($color-text-contrast, 20%);

// BACKGROUNDS

$bg-color-body: $color-white; // needed
$bg-color-nav: $color-grey-darker-trans8; // needed 
$bg-color-nav-logo: $color-grey-darker-trans8; // needed
$bg-color-nav-highlight: $color-black; // needed
$bg-color-black: $color-black; // needed
$bg-color-transparent: rgba(0,0,0,0.4);
$bg-color-footer: $color-grey-darker; // needed
$bg-color-primary: $color-1; // needed
$bg-color-secondary: $color-1; // needed


// ********************
// RESPONSIVE
//

$size-xs-max: 767px;
$size-sm-min: 768px;
$size-sm-max: 990px;
$size-md-min: 1250px;
$size-md-max: 1299px;
$size-lg-min: 1300px;

$size-nav-shift-breakpoint: $size-md-min; // change height
$size-nav-toggle-breakpoint: $size-md-min; // show mobile menu
$size-slider-breakpoint: 480px;

// ********************
// SIZES
//

$size-footer-height: 100px; // needed
$size-footer-width-desktop: 50%; // needed
$size-footer-width-mobile: 100%; // needed
$size-nav-height: 100px; // needed
$size-nav-height-mobile: 75px; // needed
$size-nav-subitem: 25px; // needed
$size-nav-toggle-bar-height: 2px; // needed

$size-info-strip: 50px;  // theme

// ******************
// Z-INDEXES
//
$z-index-0: 0;
$z-index-animation-0: 10;
$z-index-animation-1: 15;
$z-index-animation-2: 20;

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'fonts'; // utility classes for fonts
@import 'spacing'; // utility classes for spacing
@import 'colors'; // utility classes for colors
@import 'flex'; // utility classes for flex box

@import 'hover';
@import 'responsive';
@import 'typography';
@import 'buttons';
@import 'layerslider';
@import 'animation';
