/*****************************************************/
// MENU
/* needs main.js to toggle var menu = ".menu-mobile"; */

nav.module-menu {
    position: relative;
    padding-bottom: $size-nav-height;
    @media screen and (max-width: $size-nav-shift-breakpoint) {
        padding-bottom: $size-nav-height-mobile;
    }

    ul {
        list-style-type: none;
    }

    &.scrolled {
        .menu-desktop {
            position: fixed;
        }
    }

    .menu-desktop {
        @extend .flex;
        z-index: 100;
        width: 100%;
        height: $size-nav-height;
        position: fixed;
        top: 0;
        @extend .bg-color-nav;
        //background-color: rgba(161, 161, 161, 0.8588235294117647);
        &.nav-up {
            top: -$size-nav-height;
        }

        @extend .bg-color-nav;
        @include transition(all 0.2s ease);
        @media screen and (max-width: $size-nav-shift-breakpoint) {
            height: $size-nav-height-mobile;
        }

        .wrapper {
            &.left {
                flex: 0 0 auto;

                .logo {
                    height: 100%;
                    width: auto;
                    @extend .flex;
                    @extend .justify-center;
                    @extend .items-center;
                    @extend .px6;

                    a {
                        @extend .text-color-nav;
                        @extend .font-nav-logo;

                        &:hover {
                            @extend .text-color-nav-hover;
                        }
                    }

                    @media screen and (max-width: $size-nav-shift-breakpoint) {
                        max-width: 220px;
                    }
                }
            }

            &.right {
                flex: auto;
                @extend .flex;
                @extend .flex-column;
                @extend .justify-center;
                @extend .items-end;

                .main-menu-top, .main-menu {
                    height: 50%;
                    @media screen and (max-width: $size-nav-toggle-breakpoint) {
                        display: none;
                    }

                    & > ul {
                        @extend .flex;
                        @extend .flex-wrap;
                        @extend .justify-end; // pour menu a droite
                        // @extend .justify-center; // pour menu au centre de l'espace
                        // @extend .justify-start; // pour menu a gauche
                        @extend .items-center; // pour menu centré verticalement
                        // @extend .items-start; // pour menu en haut
                        // @extend .items-end; // pour menu en bas
                        padding: 0 10px 0 0;
                        @extend .m0;
                        height: 100%;

                        li {
                            height: 100%;
                            transition: all linear 0.1s;
                            @extend .flex;
                            @extend .items-center;
                            cursor: pointer;
                            // @extend .items-start; // pour menu en haut
                            // @extend .items-end; // pour menu en bas
                            //@extend .pr4;
                            &.icon {
                            }

                            &.phone, &.lang {
                                & > a, & > span.menu-title {
                                    @extend .font-nav-phone;
                                    @extend .px4;
                                    @extend .text-color-nav-phone;
                                }

                                &:hover > a, &:hover > span.menu-title {
                                    @extend .text-color-nav-phone-hover;
                                }
                            }

                            &.lang {
                                @extend .pr8;

                                & > a, & > span.menu-title {
                                    color: $color-primary;
                                }
                            }

                            &.rendez-vous {
                                & > a {
                                    color: $color-secondary;
                                }

                                & > a:hover {
                                    color: $color-primary;
                                }
                            }
                        }

                        .facebook {
                            margin-right: 20px;
                        }

                        .phone {
                            a {
                                font-size: 20px !important;
                                padding-right: 0 !important;
                            }
                        }

                        .lang {
                            margin: 0;
                            padding-right: 0px !important;

                            a {
                                padding: 0;
                                font-size: 20px !important;
                                color: $color-white !important;
                            }
                        }
                    }
                }

                .main-menu {
                    & > ul {
                        li {
                            position: relative;
                            cursor: pointer;

                            & > a, & > span.menu-title {
                                @extend .px3;
                                @extend .text-color-nav;
                                @extend .font-nav-items;
                                // padding: 0 15px 20px;
                                transition: all linear 0.1s;
                                cursor: pointer;


                            }

                            span i {
                                font-size: 0.6rem;
                                cursor: pointer;
                            }

                            &:hover {
                                & > a, & > span.menu-title {
                                    @extend .text-color-nav-hover;
                                }
                            }

                            &.active, &:active, &:hover {
                                border-bottom: 6px solid $color-blue;

                                div, span.menu-title {
                                    @extend .text-color-nav-active;
                                    //padding-top: 6px;
                                }
                            }

                            &.dropdown:hover + .submenu,
                            &.dropdown:hover > .submenu {
                                opacity: 100;
                                visibility: visible;
                            }

                            .submenu {
                                opacity: 0;
                                visibility: hidden;
                                @extend .flex;
                                @extend .justify-end;
                                position: absolute;
                                top: $size-nav-height;
                                top: 50px;
                                left: 0;
                                display: block;
                                cursor: pointer;
                                @media screen and (max-width: $size-nav-shift-breakpoint) {
                                    top: $size-nav-height-mobile;
                                }
                                // left: 0;
                                // width: 100%;
                                padding: 0px 0;
                                background-color: $color-black;
                                transition: opacity 0.1s ease-in;
                                z-index: 1;

                                ul {
                                    @extend .flex;
                                    @extend .flex-column;
                                    @extend .justify-start;
                                    flex: 1 1 auto;
                                    margin-top: 0;
                                    // @extend .m0;
                                    @extend .p0;

                                    .subitem {
                                        padding: 5px 0;
                                        position: relative;
                                        white-space: nowrap;
                                        //border-top: 1px solid $color-black;

                                        > a {
                                            display: flex;
                                            @extend .justify-between;
                                            @extend .items-center;
                                            min-height: 30px;
                                            width: 100%;

                                            span {
                                            }

                                            i {
                                                top: -1px;
                                                font-size: 0.8rem;
                                            }
                                        }

                                        &:hover {
                                            @extend .bg-color-nav-hover;
                                            border: none;

                                            div, span, i {
                                                color: $color-blue;
                                            }

                                            @extend .bg-color-nav-hover;

                                            & > .subitem-menu {
                                                display: block;
                                            }

                                        }

                                        &:active, &.active {
                                            //@extend .bg-color-nav-active;
                                            border: none;

                                            & > a, & > a > div {
                                                color: $color-blue;
                                            }
                                        }

                                        & > a {
                                            @extend .font-nav-drop-items;
                                            color: $color-white;
                                        }

                                        //menu 2eme niveau
                                        & > .subitem-menu {
                                            position: absolute;
                                            left: 100%;
                                            top: 0;
                                            display: none;
                                            background-color: $color-black;

                                            .subitem {
                                                white-space: nowrap;

                                                & > a {
                                                    //font-weight: 400!important;
                                                    @extend .font-nav-drop-items;

                                                    div {
                                                        color: $color-white;
                                                    }
                                                }

                                                &:hover {
                                                    & > a div {
                                                        color: $color-blue;
                                                    }
                                                }

                                                &:active, &.active {
                                                    //@extend .bg-color-nav-active;
                                                    border: none;

                                                    & > a div {
                                                        color: $color-blue;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &.scrolled {
        .menu-desktop {
            // position: fixed;
            //background-color: $color-black;
        }
    }

    .empty-line, .orange-line, .red-line {
        bottom: -4px;
        height: 3px;
        width: 100%;
        background-color: transparent;
        position: absolute;
    }

    .orange-line {
        bottom: -7px;
        background-color: $color-secondary;
    }

    .red-line {
        bottom: -10px;
        background-color: $color-primary;
    }

    .menu-toggle {
        @media screen and (min-width: $size-nav-toggle-breakpoint) {
            display: none;
        }
        cursor: pointer;
        @extend .pr3;

        .bar-top, .bar-bottom {
            // box-shadow: 0px 0px 4px $color-black;
            width: 100%;
            height: $size-nav-toggle-bar-height;
            display: block;
            @extend .bg-color-nav-toggle;
            background: $color-white;
        }

        .bar-top {
            // margin-bottom: 3px;
        }

        .bar-bottom {
            // margin-top: 1px;
        }

        .word {
            // text-shadow: 0px 0px 6px white;
            padding: 2px;
            @extend .font-1-bold;
            @extend .text-color-nav-toggle;
            padding-bottom: 1px;
        }
    }

    /************ menu mobile ***************************/
    /************ menu mobile ***************************/
    #menu-mobile {
        width: 100%;
        top: 0;
        right: -100%;
        z-index: 109;
        // overflow: hidden;
        position: fixed;
        @extend .bg-color-nav-toggle;
        transition: all .5s ease-in;

        &.toggled {
            overflow-y: auto;
            bottom: 0; // pour avoir le scroll
            transform: translate(-100%, 0%);
        }

        .menu-toggle {
            float: right;
            margin: 15px 0 0 0;

            .word {
                @extend .text-color-nav;
            }
        }

        ul {
            margin: 25px auto;
            padding: 30px;

            li {
                // position: relative;
                text-align: center;
                line-height: 100%;
                padding: 15px;
                //border-bottom: 1px solid $color-white;
                padding-bottom: 12px;

                &:nth-last-child(1) {
                    border-bottom: none;
                }

                &.top-bar {
                    display: flex;
                    @extend .flex-wrap;
                    @extend .justify-between;
                    @extend .items-center;

                    div.icons {
                        flex: 1 1;

                        & > a {
                            img {
                                margin: 5px;
                            }
                        }
                    }

                    a {
                        flex: 1 1;
                    }
                }

                & > a, & > .menu-title {
                    @extend .font-nav-items;
                    @extend .text-color-nav;
                    font-weight: 400;
                    font-size: 16px;
                }

                .submenu {
                    margin: 0px auto;
                    padding: 10px 0 10px;
                    padding-bottom: 0;
                    ul {
                        margin: 0;
                        padding: 0;                       

                        .subitem-menu {
                            margin-top: 17px;                            
                        }

                        li.subitem {
                            border-bottom: none;
                            padding-top: 10px;
                            padding-bottom: 10px;
                            .menu-title {
                                margin-right: 20px;
                                font-weight: 700;

                            }
                            ul {
                                border-left: 1px $color-1 solid;
                                li {
                                    padding: 5px 20px;
                                }
                            }
                            a, a div {
                                @extend .font-nav-drop-items;
                                @extend .text-color-nav;
                                font-weight: 500;
                                font-size: 16px;
                            }

                            &:hover {
                                > a, > a div {
                                    @extend .text-color-nav-hover;
                                }
                            }

                            &.active {
                                a, a div {
                                    @extend .text-color-nav-active;
                                }
                            }
                        }
                    }

                }

                &:hover {
                    > a, > a div {
                        @extend .text-color-nav-hover;
                    }
                }

                &.active, &.phone, &.lang {
                    > a, > a div, span {
                        color: $color-white;
                        font-size: 19px;
                    }
                }
            }
        }
    }
}
