html {
    font-size: $font-size-base-lg; // used for rem units
}


body {
    @extend .text-color;
    @extend .font-1;
    line-height: 1;
    a {
        @extend .text-color-primary;
        @extend .font-1;
        text-decoration: none !important;
        &:hover, &:focus {
            text-decoration: none !important;
             @extend .text-color-contrast;
        }
    }
    a[href^=tel], a[href^=mailto] {
        &:hover {

        }
    }
}

h1, h2, h3, h4, h5, h6 {
    @extend .font-1;
}

p, li {
    @extend .font-main;
    line-height: 1.4;
    font-weight: 400;
    text-align: justify;
}
ul {
  padding-left: 25px;
}

.text p, .text div {
    @extend .text-color;
    line-height: 1.4;
}
.text p, .text div {
    @extend .font-1;
}
.text strong {
    color: $color-primary;
    font-weight: 900;
}
.text em {
    color: $color-2;
    font-style: normal;
    font-weight: 600;
}
